@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,300i,400,400i,700,700i');

//modules
@import 'modules/all';

//Theme
$primary : #2a266c;
$grey-dark : #777992;
$red : #E93725;

//Text
$txt-sm : 12px;
$txt-lg : 20px;

body {
  font-family: 'Roboto', sans-serif;
  color: $grey-dark;
  letter-spacing: .02em;
  padding-top: 80px;

  &.alt {
    padding-top: 0;

    .navbar-default {
      background: none;

      &.solid {
        background: $primary;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        transition: .2s ease;
      }

    }
  }

  &.register, &.login {
    background: url(../img/banner/bg-banner.jpg) no-repeat;
    background-size: cover;
    padding-top: 0;
  }

  &.login {

    .header {
      text-align: center;
      padding: 70px 0 40px;
    }
  }
}

html,
body {
  height: 100%;
}

h1,h2,h3,h4,h5 {
  font-family: 'Roboto Slab', serif;
}

a {
  transition: .2s ease;

  &:hover {
    transition: .2s ease;
    text-decoration: none;
  }
}

//Custom Bootstrap
.text-muted {
    color: $grey-light;
}
//-- Button
.btn {
  border-radius: 3px;

    &.btn-primary {
        background: $blue;
        border-color: $blue;

        &:hover {
            background: darken($blue, 5%);
        }
    }

  &.btn-secondary {
    background: $red;
    color: #fff;

    &:hover {
      background: darken($red, 6%);
    }
  }

  //--size
  &.btn-lg {
    font-size: 16px;
  }
}
//-- Custom Navbar
$bgDefault      : $primary;
$bgHighlight    : #ffffff;
$colDefault     : #fff;
$colHighlight   : rgba(255,255,255,.8);
$dropDown       : true;
$bg-dropdown    : $primary;
.navbar-default {
  background: $bgDefault;
  border-color: transparent;
  min-height: 80px;

  @include mobile () {
    min-height: 70px;
  }

  .navbar-brand {
    padding-top: 20px;
    color: $colDefault;

    &:hover, &:focus {
      color: $colHighlight;
    }

    img {

      @include mobile() {
        height: 30px;
      }
    }
  }
  .navbar-text {
    color: $colDefault;
  }
  .navbar-nav {

    @include desktop() {
      margin-right: -30px;
    }
    > li {
      > a {
        color: $colDefault;
        transition: border .2s ease-in-out;
        -webkit-transition: border .2s ease-in-out;
        -moz-transition: border .2s ease-in-out;
        margin-top: 20px;
        transition: .2s ease;
        text-transform: uppercase;
        font-size: 16px;
        padding-top: 10px;

        @include mobile () {
          color: #fff;
          margin-top: 0;
        }

        .fa {
          margin-right: 5px;

          @include mobile () {
            width: 30px;
            text-align: center;
          }
        }

        &:hover,  &:focus {
          color: $colHighlight;

         }
       }
      @if $dropDown {
        > .dropdown-menu {
          font-size: 13px;

          > li {
            > a {
              color: #fff;
              padding: 15px 20px;

              &:hover,  &:focus {
                color: #fff;
                background-color: rgba(0,0,0,.15);
                }

                .fa {
                  margin-right: 5px;
                    width: 30px;
                    text-align: center;
                }



              }
            > .divider {
              background-color: $bgHighlight;
                  }
                }
              }

              .list {
                width: 260px;

                li {
                  border-bottom: 1px solid rgba(0,0,0,.1);

                  a {
                    white-space: normal;
                    .date {
                      display: block;
                      font-size: $txt-sm;
                      color: rgba(255,255,255,0.5);
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
          }
    @if $dropDown {
      .open .dropdown-menu > .active {
        > a, > a:hover, > a:focus {
          color: $bgHighlight;
          background-color: $bg-dropdown; }}}
    > .active {
      > a, > a:hover, > a:focus {
        color: $colHighlight;
        background-color: $bgHighlight; }}
    > .open {
      > a, > a:hover, > a:focus {
        color: $bgHighlight;
        background-color: $bg-dropdown; }}}
  .navbar-toggle {
    border-color: $bgHighlight;
    margin-top: 18px;

    &:hover, &:focus {
      background-color: $bgHighlight; }
    .icon-bar {
      background-color: $colDefault; }
    }
  .navbar-collapse,
  .navbar-form {
    border-color: $colDefault;

    @include mobile() {
      margin-top: 10px;
      background: $bg-dropdown;
    }
  }
  .navbar-link {
    color: $colDefault;
    &:hover {
      color: $colHighlight; }}

      li.menu-drop {

        >a {
          padding: 21px;
        }

        &.open {

          .dropdown-menu {
            background-color: $bg-dropdown;
            color: #fff;
            border: none;
            min-width: 200px;
            border-radius: 0;
            padding: 0;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
          }
        }

        span.list-count {
          display: inline-block;
          background: $red;
          color: #fff;
          font-size: 10px;
          border-radius: 18px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }

      .login {

        @include desktop() {
          a {
            border: 1px solid #fff;
            border-radius: 3px;
            padding: 10px 20px;
            background: $red;
          }
        }
      }

    }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu {
    > li > a {
      color: $colDefault;
      &:hover, &:focus {
        color: $colHighlight; }}
    > .active {
      > a, > a:hover, > a:focus {
        color: $colHighlight;
        background-color: $bgHighlight; }}}
}

@media (min-width: 768px) {
  .navbar-nav > li {
    margin: 0 15px;

    > a {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 5px;
    }
  }

}

//-- Form
.form-control:focus {
  box-shadow: none;
  border-color: $primary;
}

//Global --section
section {

  &.front {
    text-align: center;
    padding: 70px 0;

    @include mobile() {
      padding: 30px 0;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 60px;
  }

  .box {
    margin-bottom: 0;

    @include mobile() {
      margin-bottom: 40px;
    }

    .box-icon {
      img {
        height: 100px;
        margin-bottom: 30px;

        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
      }
      .transition {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
      }
    }
    h4 {
      margin-top: 0;
      margin-bottom: 30px;
      color: lighten($primary, 45%);
    }
  }
}
//Banner
section.banner {
  background: url(../img/banner/bg-banner.jpg) no-repeat;
  background-size: cover;
  text-align: center;
  padding: 140px 0;
  color: #fff;
  height: 100vh;
  font-size: 16px;

  @include desktop() {
      max-height: 600px;
      padding: 200px 0;
  }

  h1 {
    font-weight: 700;
  }
  .btn {
    margin-top: 30px;

    @include mobile() {
      width: 230px;
    }

    &.login {
      display: none;

      @include mobile() {
        display: inline-block;
      }
    }
  }

  .logo-app {
      margin-top: 40px;

      img {
          margin: 5px ;
      }
  }
}

//
section.main-container {
  padding: 100px 0;
}


footer {
  background: $primary;
  padding: 50px 0;
  margin-top: 70px;
  color: #bdbaf2;

  @include mobile() {
    text-align: center;
    padding: 30px 0;
  }

  h4 {
    color: #918bf6;
    margin-bottom: 20px;

    @include mobile() {
      margin-top: 30px;
    }
  }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 5px;

      a {
        color: #bdbaf2;

        .fa {
          width: 20px;
          text-align: center;
          margin-right: 5px;
        }

        &:hover {
          color: darken(#bdbaf2, 10%);
        }
      }
    }

    &.social {
      margin-top: 10px;
    }
  }

    .wrap-partners {
          img {
                margin-bottom: 20px;
                max-height: 30px;
                display: block;
                opacity: .6;

                @include mobile() {
                    margin: 0 20px 20px;
                    display: inline-block;
                }
          }
    }

  .copytext {
    font-size: $txt-sm;
    margin-top: 20px;
  }
}

.footer {
  &.login {
    color: #fff;
  }
}

//PAGE-register
section.register-form {
  padding: 50px 0 20px;
  color: #fff;


  @include mobile() {
    padding: 100px 0;
  }

  .wrapper {
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -ms-box-orient: horizontal;
    box-orient: horizontal;

    @include mobile() {
      display: inline;
    }

    .meta {
      background: $primary;
      -ms-flex: 1;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-box-flex: 1;
      box-flex: 1;
      padding: 80px 40px;
      text-align: center;

      p {
        margin-top: 30px;
        font-weight: 300;
      }
    }
    .form {
      background: #fff;
      color: $grey-dark;
      padding: 40px;

      button [type=submit] {
        margin-left: 20px;
      }
    }
  }
}

//Panel
.panel {

  #login_form {
    margin-top: 30px;
    .input-group-addon {
      background: #fff;
    }
  }
}
