//Theme
$blue : #33479b; //#33449b;
$red : #e93826;
$green : #43a23d;
$grey : #535458;
$grey-light : lighten($grey, 30%);
$grey-lighter : lighten($grey, 55%);
$grey-lightest : lighten($grey, 63%);

$primary : $blue;
$info : #2196F3;
$danger : #bd0e36;
$warning : #f39c20;
$success : $green;

$dark : #000;

$premium : $red;

//Font
$txt-sm : 12px;
$txt-med : 16px;
$txt-lg : 24px;
